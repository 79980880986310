import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert,
  Label,
} from "reactstrap";
import { Select, Spin, Divider, Modal as Modal1, Tooltip } from "antd";
import {
  baseUrl,
  Brand_LSP,
  path_province,
  path_city,
  path_district,
  path_village,
  path_institution,
  onlyText,
} from "../../components/config/config";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import Axios from "axios";
import { Digest, PublicDigest } from "../../containers/Helpers/digest";
import moment from "moment";
import "moment/locale/id";
import "../../css/loaderComponent.css";
import "antd/dist/antd.css";
import LoadingOverlay from "react-loading-overlay";
import { multiLanguage } from "../../components/Language/getBahasa";
import LabelRequired from "../../components/Label/LabelRequired";
import SignatureCanvas from "react-signature-canvas";
import { UploadPicture } from "../../components/Button/ButtonUploadPicture";
import { PostData, PostRegister } from "../../services/PostData";
import ButtonBack from "../../components/Button/ButtonBack";
import LabelPlaceholder from "../../components/Label/PlaceholderLabelAlamat";

const { Option } = Select;


class RegistrasiAsesor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        form_name: "",
        form_type: "",
      },
      payload: [],
      payloadProvince: [],
      payloadCity: [],
      payloadDistrict: [],
      payloadVillage: [],
      payloadReligion: [],
      province_id: "",
      kota_id: "",
      kecamatan_id: "",
      kelurahan_id: "",
      modalSignature: false,
      fetching: false,
      signature: "",
      uploadTTD: [],
      message: "",
      hidden: true,
      response: "",
      symbolsArr: ["e", "E", "+", "-", "."],
      modalBack: false,
      place_of_birth: "",
      date_of_birth: "",
      payloadInstitution: [],
      toolFirst: false,
      toolLast: false,
    };
  }

  handleFocus = (type) => {
    if(type === "first"){
      this.setState({ toolFirst:true })
    }else{
      this.setState({ toolLast:true })
    }
  }

  handleBlur = (type) => {
    if(type === "first"){
      this.setState({ toolFirst:false })
    }else{
      this.setState({ toolLast:false })
    }
  }

  handleChange = (event, errors, values) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors,
      values,
    });
  };

  onChangeInstitution = (value) => {
    this.setState({
      institution_id: value,
    });
  };
  handleUsernameEmail = (input) => (event) => {
    if (input === "username") {
      this.setState({ [input]: event.target.value });
      this.Get_Username(event.target.value);
    } else {
      this.setState({ [input]: event.target.value });
      this.Get_Emails(event.target.value);
    }
  };

  Get_Username = (value) => {
    this.setState({
      loading: true,
    });
    const path = `/public/users_validation?username=${value}`;
    Axios(baseUrl + path)
      .then((response) => {
        this.setState({
          loading: false,
          hidden: true,
          message: "",
        });
      })
      .catch((error) => {
        this.setState({
          response: error.response.data.error.code,
        });

        switch (this.state.response) {
          case 400:
            this.setState({
              hidden: false,
              message: "Username sudah terdaftar",
              loading: false,
            });
            break;

          default:
            break;
        }
      });
  };
  Get_Emails = (value) => {
    this.setState({
      loading: true,
    });
    const path = `/public/users_validation?email=${value}`;
    Axios(baseUrl + path)
      .then((response) => {
        this.setState({
          loading: false,
          hidden: true,
          message: "",
        });
      })
      .catch((error) => {
        this.setState({
          response: error.response.data.error.code,
        });

        switch (this.state.response) {
          case 400:
            this.setState({
              hidden: false,
              message: "Email sudah terdaftar",
              loading: false,
            });
            break;

          default:
            break;
        }
      });
  };

  toggleSignature = () => {
    this.setState({
      modalSignature: !this.state.modalSignature,
    });
  };
  uploadProps = (upload) => {
    this.setState({
      signature: upload.base64,
      modalSignature: !this.state.modalSignature,
    });
  };

  handleBack = () => {
    Modal1.confirm({
      content: "Apakah anda yakin akan ke halaman login ?",
      onOk() {
        localStorage.clear();
        window.location.replace("/login");
      },
    });
  };
  changeSignature = () => {
    this.setState({
      signature: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
      modalSignature: !this.state.modalSignature,
    });
  };

  Get(options, response) {
    Axios(options)
      .then((res) => {
        this.setState({
          [response]: res.data.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace("/login");
        } else if (error.response.status === 419) {
          this.errorTrial();
        }
      });
  }

  componentDidMount() {
    const optionInstitution = {
      url: `${baseUrl}/public/${path_institution}?limit=unlimited`,
      data: null,
    };
    const optionsJobs = {
      url: `${baseUrl}/public/jobs?limit=100`,
      data: null,
    };
    const optionsReligion = {
      url: `${baseUrl}/public/religion?limit=100`,
      data: null,
    };
    const optionsProvince = {
      url: `${baseUrl}/public/${path_province}?limit=100`,
      data: null,
    };
    this.Get(optionInstitution, "payloadInstitution");
    this.Get(optionsJobs, "payload");
    this.Get(optionsReligion, "payloadReligion");
    this.Get(optionsProvince, "payloadProvince");
  }

  onSearch = (value) => {
    this.setState({
      fetching: true,
    });

    if (value !== "") {
      const optionsProvince = {
        url: `${baseUrl}/public/${path_province}?limit=100&search=${value}`,
        data: null,
      };
      Axios(optionsProvince).then((response) => {
        this.setState({
          payloadProvince: response.data.data,
          fetching: false,
        });
      });
    } else {
      this.setState({
        payloadProvince: [],
        fetching: false,
      });
    }
  };

  onChangeProvince = (input) => (value) => {
    // const dataCity = this.state.kota_id.slice(0, 2);
    // if (value.key != dataCity) {
    //   this.setState({
    //     payloadDistrict: [],
    //     payloadVillage: [],
    //     kecamatan_id: "",
    //     kelurahan_id: "",
    //     province_id: value.key,
    //   });
    // } else {
    //   this.setState({
    //     province_id: value.key,
    //     fething: false,
    //   });
    // }

    const dataCity = this.state.kota_id.slice(0, 2);
    if (value != dataCity) {
      this.setState({
        kecamatan_id: "",
        kelurahan_id: "",
        kota_id: "",
        province_id: value,
      });
    } else {
      this.setState({
        province_id: value,
        fething: false,
        validate: true,
      });
    }
    const optionsCity = {
      url: `${baseUrl}/public${path_city}?limit=100&province_id=${
        value.key == undefined ? "" : value.key
      }&limit=unlimited`,
      data: null,
    };

    this.Get(optionsCity, "payloadCity");
  };
  onSearchCity = (value) => {
    this.setState({
      fetching: true,
    });
    if (value !== "") {
      const optionsCity = {
        url: `${baseUrl}/public${path_city}?limit=100&province_id=${this.state.province_id}&search=${value}`,
        data: null,
      };
      Axios(optionsCity).then((response) => {
        this.setState({
          payloadCity: response.data.data,
          fetching: false,
        });
      });
    } else {
      this.setState({
        payloadCity: [],
        fetching: false,
      });
    }
  };
  onChangeCity = (input) => (value) => {
    this.setState({
      kota_id: value.key,
    });

    const optionsDistrict = {
      url: `${baseUrl}/public${path_district}?limit=100&kota_id=${
        value.key == undefined ? "" : value.key
      }&limit=unlimited`,
      data: null,
    };

    this.Get(optionsDistrict, "payloadDistrict");
  };
  onSearchDistrict = (value) => {
    this.setState({
      fetching: true,
    });

    if (value !== "") {
      const optionsDistrict = {
        url: `${baseUrl}/public${path_district}?limit=100&kota_id=${this.state.kota_id}&search=${value}`,
        data: null,
      };
      Axios(optionsDistrict).then((response) => {
        this.setState({
          payloadDistrict: response.data.data,
          fetching: false,
        });
      });
    } else {
      this.setState({
        payloadDistrict: [],
        fetching: false,
      });
    }
  };
  onChangeDistrict = (input) => (value) => {
    this.setState({
      kecamatan_id: value.key,
    });
    const optionsVillage = {
      url: `${baseUrl}/public${path_village}?limit=100&kecamatan_id=${
        value.key == undefined ? "" : value.key
      }&limit=unlimited`,
      data: null,
    };

    this.Get(optionsVillage, "payloadVillage");
  };

  onSearchVillage = (value) => {
    this.setState({
      fetching: true,
    });

    if (value !== "") {
      const optionsVillage = {
        url: `${baseUrl}/public${path_village}?limit=100&kecamatan_id=${this.state.kecamatan_id}&search=${value}`,
        data: null,
      };
      Axios(optionsVillage).then((response) => {
        this.setState({
          payloadVillage: response.data.data,
          fetching: false,
        });
      });
    } else {
      this.setState({
        payloadVillage: [],
        fetching: false,
      });
    }
  };

  onChangeVillage = (input) => (value) => {
    this.setState({
      kelurahan_id: value.key,
    });
  };
  toggle = () => {
    this.setState({
      modalUpload: !this.state.modalUpload,
    });
  };

  onChangeReligion = (input) => (value) => {
    this.setState({
      religion: value,
    });
  };
  onChangeJobs = (input) => (value) => {
    this.setState({
      jobs: value,
    });
  };
  handleValidation = () => {
    // province_id: "",
    // kota_id: "",
    // kecamatan_id: "",
    // kelurahan_id: "",
    if (
      this.state.username == "" ||
      this.state.email == "" ||
      this.state.contact == "" ||
      this.state.first_name == "" ||
      this.state.institution_id === "" ||
      this.state.gender_code == "" ||
      this.state.place_of_birth == "" ||
      this.state.date_of_birth == "" ||
      this.state.religion == "" ||
      this.state.registration_number == "" ||
      this.state.institution == "" ||
      this.state.jobs == "" ||
      this.state.pendidikan_terakhir == "" ||
      this.state.address == "" ||
      this.state.signature == ""
    ) {
      this.setState({
        hidden: false,

        message: multiLanguage.alertInput,
        loading: false,
      });
    } else if (
      this.state.nik == "" ||
      this.state.nik.length < 16 ||
      this.state.nik.length > 16
    ) {
      this.setState({
        hidden: false,
        message: "Nik harus 16 digit",
        loading: false,
      });
    } else if (this.state.province_id == "") {
      this.setState({
        hidden: false,
        message: "Propinsi di KTP harus diisi ",
        loading: false,
      });
    } else if (this.state.kota_id == "") {
      this.setState({
        hidden: false,
        message: "Kota di KTP harus diisi ",
        loading: false,
      });
    } else if (this.state.kecamatan_id == "") {
      this.setState({
        hidden: false,
        message: "Kecamatan di KTP harus diisi ",
        loading: false,
      });
    } else if (this.state.kelurahan_id == "") {
      this.setState({
        hidden: false,
        message: "Kelurahan di KTP harus diisi ",
        loading: false,
      });
    } else {
      this.handleSubmit();
    }
  };
  handleChangeRoleStateASESI = (value) => {
    this.setState({
      apl_document_state: value,
    });
  };

  handleSubmit = (event) => {
    this.setState({ fireRedirect: true });

    this.setState({
      loading: true,
    });
    // const data = {};
    // data["username"] = this.state.username;
    // data["email"] = this.state.email;
    // data["contact"] = this.state.contact;
    // data["first_name"] = this.state.first_name;
    // data["last_name"] = this.state.last_name;
    // data["gender_code"] = this.state.gender_code;
    // data["place_of_birth"] = this.state.place_of_birth;
    // data["date_of_birth"] = this.state.date_of_birth;
    // data["religion"] = this.state.religion;
    // data["registration_number"] = this.state.registration_number;
    // data["nik"] = this.state.nik;
    // data["pendidikan_terakhir"] = this.state.pendidikan_terakhir;
    // data["address"] = this.state.address;
    // data["signature"] = this.state.signature;

    PostRegister("/public/users/accessors", {
      username: this.state.username,
      email: this.state.email,
      contact: this.state.contact,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      gender_code: this.state.gender_code,
      place_of_birth: this.state.place_of_birth,
      institution_id: this.state.institution_id,
      date_of_birth: this.state.date_of_birth,
      religion: this.state.religion.key,
      registration_number: this.state.registration_number,
      institution: this.state.institution,
      nik: this.state.nik,
      pendidikan_terakhir: this.state.pendidikan_terakhir,
      address: this.state.address,
      signature: this.state.signature,
      jobs_code: this.state.jobs.key,
      kelurahan_id: this.state.kelurahan_id,
    })
      .then((res) => {
        localStorage.setItem("email", this.state.email);
        window.location.replace("/confirm");
        this.setState({
          loading: false,
        });

        return;
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        this.setState({
          response: error.data.error.code,
        });
        switch (this.state.response) {
          case 400:
            this.setState({
              hidden: false,
              message: error.data.error.message,
              loading: false,
            });
            break;

          case 409:
            this.setState({
              hidden: false,
              message: multiLanguage.alertAlready,
              loading: false,
            });
            break;

          default:
            break;
        }
      });
  };

  render() {
    const {
      fetching,
      payload,
      payloadReligion,
      payloadProvince,
      payloadDistrict,
      payloadCity,
      payloadVillage,
      province_id,
      kota_id,
      kecamatan_id,
      kelurahan_id,
      payloadInstitution,
    } = this.state;

    // console.log("username", this.state.username);
    // console.log("email", this.state.email);
    // console.log("contact", this.state.contact);
    // console.log("first_name", this.state.first_name);
    // console.log("last_name", this.state.last_name);
    // console.log("gender_code", this.state.gender_code);
    // console.log("place_of_birth", this.state.place_of_birth);
    // console.log("date_of_birth", this.state.date_of_birth);
    // console.log("religion", this.state.religion);
    // console.log("registration_number", this.state.registration_number);
    // console.log("nik", this.state.nik);
    // console.log("jobs", this.state.jobs);
    // console.log("pendidikan_terakhir", this.state.pendidikan_terakhir);
    // console.log("address", this.state.address);
    // console.log("signature", this.state.signature);
    const { Logo } = Brand_LSP("demo");
    const now = new Date(new Date().setFullYear(new Date().getFullYear() - 17))
      .toJSON()
      .split("T")[0];
    let sevenYearsAgo = new Date();
    sevenYearsAgo.setFullYear(sevenYearsAgo.getFullYear() - 200);
    sevenYearsAgo = sevenYearsAgo.toJSON().split("T")[0];
    var reg = /^\d+$/;
    return (
      <div>
        <LoadingOverlay active={this.state.loading} spinner text="Loading..">
          <AvForm
            action=""
            encType="multipart/form-data"
            className="form-horizontal"
          >
            {/* <AvGroup row>
              <Col md="12">
                <img
                  src={Logo}
                  style={{
                    width: 200,
                    float: "right",
                    marginRight: "100px",
                    marginTop: "15px",
                  }}
                />
              </Col>
            </AvGroup> */}
            <AvGroup row>
              <Col md="3" style={{ marginLeft: 145, marginTop: "50px" }}>
                <LabelRequired fors="username" label={"Nama Pengguna"} />
                <AvInput
                  type="text"
                  id="username"
                  name="username"
                  placeholder={"Username"}
                  onChange={this.handleUsernameEmail("username")}
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
              <Col md="3" style={{ marginTop: "50px" }}>
                <LabelRequired fors="email" label={"Email"} />
                <AvInput
                  type="text"
                  id="email"
                  name="email"
                  placeholder={"Email"}
                  onChange={this.handleUsernameEmail("email")}
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
              <Col md="3" style={{ marginTop: "50px" }}>
                <LabelRequired fors="contact" label={"Nomor Telepon"} />

                <AvField
                  type="text"
                  id="contact"
                  name="contact"
                  maxLength="14"
                  onChange={this.handleChange}
                  placeholder={"Nomor Telepon"}
                  defaultValue={this.state.contact}
                  onKeyDown={(e) =>
                    this.state.symbolsArr.includes(e.key) && e.preventDefault()
                  }
                  pattern="^[0-9]*$"
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
            </AvGroup>
            <AvGroup row>
              <Col md="3" style={{ marginLeft: 145 }}>
                <LabelRequired
                  fors="first_name"
                  label={"Nama Depan"}
                  tooltip={<Tooltip visible={this.state.toolFirst} title="Nama sesuai KTP"><i onMouseLeave={() => this.handleBlur('first')} onMouseEnter={() => this.handleFocus('first')} className="fa fa-question-circle"></i></Tooltip>}
                />
                <AvInput
                  onFocus={() => this.handleFocus('first')}
                  onBlur={() => this.handleBlur('first')}
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder={"Nama sesuai KTP"}
                  onChange={this.handleChange}
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
              <Col md="3">
                <Label style={{ marginTop: 5 }}>Nama Belakang <Tooltip visible={this.state.toolLast} title="Abaikan jika tidak ada nama belakang"><i onMouseLeave={() => this.handleBlur('last')} onMouseEnter={() => this.handleFocus('last')} className="fa fa-question-circle"></i></Tooltip></Label>
                <AvInput
                  onFocus={() => this.handleFocus('last')}
                  onBlur={() => this.handleBlur('last')}
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder={"Abaikan jika tidak ada nama belakang"}
                  onChange={this.handleChange}
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
              <Col md="3">
                <LabelRequired
                  fors="gender_code"
                  label={multiLanguage.gender}
                />

                <AvRadioGroup
                  inline
                  name="gender_code"
                  required
                  style={{ marginLeft: 20 }}
                  // defaultValue={values.gender_code}
                  errorMessage={multiLanguage.alertRadioButton}
                >
                  <AvRadio
                    label={multiLanguage.female}
                    value="F"
                    onChange={this.handleChange}
                  />
                  <AvRadio
                    label={multiLanguage.male}
                    value="M"
                    onChange={this.handleChange}
                  />
                </AvRadioGroup>
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
            </AvGroup>
            <AvGroup row>
              <Col md="3" style={{ marginLeft: 145 }}>
                <LabelRequired fors="place_of_birth" label={"Tempat Lahir"} />

                <AvInput
                  type="text"
                  id="plcae_of_birth"
                  name="place_of_birth"
                  placeholder={"Tempat Lahir"}
                  onChange={this.handleChange}
                  // required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
              <Col md="3">
                <LabelRequired fors="date_of_birth" label={"Tanggal Lahir"} />

                <AvInput
                  type="date"
                  id="date_of_birth"
                  name="date_of_birth"
                  min={sevenYearsAgo}
                  max={now}
                  placeholder={"Tanggal Lahir"}
                  onChange={this.handleChange}
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>

              <Col md="3">
                <LabelRequired fors="nik" label={"NIK"} />
                <AvField
                  type="text"
                  id="nik"
                  name="nik"
                  maxLength="16"
                  pattern="[0-9]*"
                  onChange={this.handleChange}
                  value={this.state.nik}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={(e) =>
                    this.state.symbolsArr.includes(e.key) && e.preventDefault()
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: multiLanguage.alertName,
                    },
                    maxLength: {
                      value: 16,
                      errorMessage: "Nik anda melebihi 16 digit",
                    },
                  }}
                />
              </Col>
            </AvGroup>
            <AvGroup row>
              <Col md="3" style={{ marginLeft: 145 }}>
                <LabelRequired
                  fors="registration_number"
                  label={"Nomor Registrasi Asesor"}
                />

                <AvInput
                  type="text"
                  id="registration_number"
                  name="registration_number"
                  placeholder={"Nomor Registrasi Asesor"}
                  onChange={this.handleChange}
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>

              <Col md="3">
                <LabelRequired
                  fors="institution"
                  label={multiLanguage.institute}
                />
                <Select
                  showSearch
                  onSearch={this.onSearchInstitution}
                  placeholder={
                    <LabelPlaceholder
                      fors="institution_id"
                      label={
                        "-" +
                        multiLanguage.select +
                        " " +
                        multiLanguage.institute +
                        "-"
                      }
                    />
                  }
                  filterOption={false}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  style={{ width: "100%" }}
                  onChange={this.onChangeInstitution}
                >
                  {payloadInstitution.map((d) => (
                    <Option key={d.institution_id}>{d.institution_name}</Option>
                  ))}
                </Select>

                {/* <AvField
                  type="text"
                  id="institution"
                  name="institution"
                  value={this.state.institution}
                  placeholder={"Institusi"}
                  onChange={this.handleChange}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: multiLanguage.alertName,
                    },
                  }}
                /> */}
              </Col>
              <Col md="3">
                <LabelRequired fors="religion" label={multiLanguage.religion} />

                <Select
                  showSearch
                  labelInValue
                  placeholder={
                    <Label style={{ color: "#5c6873" }}>
                      {multiLanguage.select + " " + multiLanguage.religion}
                    </Label>
                  }
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  onChange={this.onChangeReligion("religion")}
                  style={{ width: "100%" }}
                >
                  {payloadReligion.map((d) => (
                    <Option key={d.row_id}>{d.religion}</Option>
                  ))}
                </Select>
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
            </AvGroup>
            <AvGroup row>
              <Col md="3" style={{ marginLeft: 145 }}>
                <LabelRequired fors="jobs_code" label={multiLanguage.jobs} />

                <Select
                  showSearch
                  labelInValue
                  // value={value}
                  placeholder={
                    <Label style={{ color: "#5c6873" }}>
                      {multiLanguage.select + " " + multiLanguage.jobs}
                    </Label>
                  }
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                  onSearch={this.onSearch}
                  // onChange={this.onChangeJobs}
                  onChange={this.onChangeJobs("jobs")}
                  // style={{ width: "100%" }}
                >
                  {payload.map((d) => (
                    <Option key={d.jobs_code}>{d.jobs_name}</Option>
                  ))}
                </Select>
              </Col>
            </AvGroup>
            <Divider
              orientation="left"
              style={{
                color: "black",
              }}
            >
              Alamat sesuai KTP
            </Divider>
            <Row>
              <Col md="3" style={{ marginLeft: 145 }}>
                <LabelRequired
                  fors="province_id"
                  label={multiLanguage.province}
                />

                <Select
                  showSearch
                  labelInValue
                  placeholder={
                    <Label style={{ color: "#5c6873" }}>
                      {"-" +
                        multiLanguage.select +
                        " " +
                        multiLanguage.province +
                        "-"}
                    </Label>
                  }
                  filterOption={false}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  onChange={this.onChangeProvince("province")}
                  onSearch={this.onSearch}
                  style={{ width: "100%" }}
                >
                  {payloadProvince.map((d) => (
                    <Option key={d.province_id}>{d.province_name}</Option>
                  ))}
                </Select>
              </Col>

              <Col md="3">
                <LabelRequired
                  fors="kota_id"
                  label={"Kabupaten" + "/" + multiLanguage.city}
                />

                <Select
                  showSearch
                  labelInValue
                  // value={kota_id}
                  placeholder={
                    <Label style={{ color: "#5c6873" }}>
                      {"-" +
                        multiLanguage.select +
                        " " +
                        multiLanguage.city +
                        "-"}
                    </Label>
                  }
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  onChange={this.onChangeCity("city")}
                  onSearch={this.onSearchCity}
                  filterOption={false}
                  style={{ width: "100%" }}
                >
                  {payloadCity.map((d) => (
                    <Option key={d.kota_id}>{d.kota_name}</Option>
                  ))}
                </Select>
              </Col>

              <Col md="3">
                <LabelRequired
                  fors="kecamatan_id"
                  label={multiLanguage.district}
                />

                <Select
                  showSearch
                  labelInValue
                  // value={kecamatan_id}
                  placeholder={
                    <Label style={{ color: "#5c6873" }}>
                      {"-" +
                        multiLanguage.select +
                        " " +
                        multiLanguage.district +
                        "-"}
                    </Label>
                  }
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  onChange={this.onChangeDistrict("district")}
                  style={{ width: "100%" }}
                  onSearch={this.onSearchDistrict}
                  filterOption={false}
                >
                  {payloadDistrict.map((d) => (
                    <Option key={d.kecamatan_id}>{d.kecamatan_name}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col md="3" style={{ marginLeft: 145 }}>
                <LabelRequired
                  fors="kelurahan_id"
                  label={multiLanguage.village + "/" + "Desa"}
                />

                <Select
                  showSearch
                  labelInValue
                  placeholder={
                    <Label style={{ color: "#5c6873" }}>
                      {"-" +
                        multiLanguage.select +
                        " " +
                        multiLanguage.village +
                        "/" +
                        "Desa" +
                        "-"}
                    </Label>
                  }
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  onChange={this.onChangeVillage("district")}
                  onSearch={this.onSearchVillage}
                  filterOption={false}
                  style={{ width: "100%" }}
                >
                  {payloadVillage.map((d) => (
                    <Option key={d.kelurahan_id}>{d.kelurahan_name}</Option>
                  ))}
                </Select>
              </Col>
              <Col md="3">
                <LabelRequired fors="address" label={multiLanguage.address} />
                <AvInput
                  type="textarea"
                  id="address"
                  name="address"
                  placeholder={`Alamat`}
                  onChange={this.handleChange}
                  required
                />
              </Col>
            </Row>
            <Divider orientation="left" style={{ color: "#c8ced3" }} />
            <AvGroup row>
              <Col md="3" style={{ marginLeft: 145 }}>
                <LabelRequired
                  fors="pendidikan_terakhir"
                  label={multiLanguage.lastEducation}
                />

                <AvField
                  type="select"
                  id="pendidikan_terakhir"
                  name="pendidikan_terakhir"
                  onChange={this.handleChange}
                  // defaultValue={values.pendidikan_terakhir}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: multiLanguage.alertName,
                    },
                  }}
                >
                  <option value="">{multiLanguage.select}</option>
                  <option value="SD">SD</option>
                  <option value="SMP">SMP</option>
                  <option value="SMA/Sederajat">SMA/Sederajat</option>
                  <option value="D1">D1</option>
                  <option value="D2">D2</option>
                  <option value="D3">D3</option>
                  <option value="D4">D4</option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                  <option value="S3">S3</option>
                </AvField>
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>

              <Col md="3">
                <LabelRequired
                  fors="signature"
                  label={multiLanguage.signature}
                />

                {this.state.signature == "" && this.state.uploadTTD == "" ? (
                  <Button
                    onClick={this.toggleSignature}
                    className="btn-primary"
                  >
                    {multiLanguage.signature}
                  </Button>
                ) : this.state.signature === "data:image/png;base64," ? (
                  <Button
                    onClick={this.toggleSignature}
                    className="btn-primary"
                  >
                    {multiLanguage.signature}
                  </Button>
                ) : this.state.uploadTTD != "" ? (
                  <div>
                    <img
                      style={{
                        width: 260,
                        height: 110,
                        backgroundColor: "grey",
                      }}
                      src={this.state.uploadTTD.base64}
                    />
                    <h6> {this.state.uploadTTD.filename}</h6>

                    <h6>
                      <Button color="link" onClick={this.toggleSignature}>
                        Ganti ?
                      </Button>
                    </h6>
                  </div>
                ) : this.state.signature != "" ? (
                  <div>
                    <img
                      style={{
                        width: 260,
                        height: 110,
                        backgroundColor: "grey",
                      }}
                      src={this.state.signature}
                    />

                    <h6>
                      <Button color="link" onClick={this.toggleSignature}>
                        Ganti ?
                      </Button>
                    </h6>
                  </div>
                ) : null}
              </Col>
            </AvGroup>
            <Alert
              color="danger"
              hidden={this.state.hidden}
              className="text-center"
            >
              {this.state.message}
            </Alert>
          </AvForm>

          <Modal
            isOpen={this.state.modalSignature}
            toggle={this.toggleSignature}
          >
            <ModalHeader toggle={this.toggleSignature}>
              {multiLanguage.change} {multiLanguage.signature}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <span>{multiLanguage.alertSignature}</span>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="9">
                  <div
                    style={{
                      backgroundColor: "gray",
                      width: 400,
                      height: 400,
                      marginBottom: "5%",
                    }}
                  >
                    <SignatureCanvas
                      ref={(ref) => {
                        this.sigPad = ref;
                      }}
                      penColor="black"
                      canvasProps={{
                        width: 400,
                        height: 400,
                        className: "sigCanvas",
                      }}
                    />
                  </div>
                  {this.sigPad === {} ? (
                    ""
                  ) : (
                    <div>
                      <Button
                        color="warning"
                        onClick={this.clear}
                        style={{ marginRight: "50px" }}
                      >
                        <i className="fa fa-eraser" /> {multiLanguage.clear}
                      </Button>
                      <UploadPicture upload={this.uploadProps} />
                    </div>
                  )}
                </Col>
              </Row>
              <p />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={this.toggleSignature}>
                {multiLanguage.cancel}
              </Button>
              <Button
                type="submit"
                color="success"
                onClick={this.changeSignature}
              >
                <i className="fa fa-check" /> {multiLanguage.submit}
              </Button>
            </ModalFooter>
          </Modal>

          <p />
          <Row className="justify-content-end">
            <Col md="1.5">
              <Button
                type="submit"
                size="md"
                className="btn-primary-sm"
                onClick={this.handleBack}
              >
                <i className="fa fa-chevron-left"> </i>
                {"Login Page"}
              </Button>
            </Col>
            <Col md="1.5">
              <Button
                className="btn-submit-primary"
                type="submit"
                size="md"
                color="primary"
                style={{ marginLeft: "20px", marginRight: "180px" }}
                // onClick={this.handleSubmit}
                onClick={this.handleValidation}
              >
                <i className="fa fa-check" /> {"Register"}
              </Button>
            </Col>
          </Row>
        </LoadingOverlay>
      </div>
    );
  }
}

export default RegistrasiAsesor;
